<template>
  <v-row>
    <v-col cols="12">
      <SectionTitle title="Atividade" />
    </v-col>
    <v-col
      align="end"
      cols="12"
    >
      <v-btn
        :disabled="items.length > 0"
        color="primary"
        rounded
        @click="newCrm"
      >
        Novo CRM
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th
                v-for="(item, idx) in tableHeaders"
                :key="idx"
                :class="item.align"
              >
                {{ item.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in items"
              :key="item"
            >
              <td>{{ item.title }}</td>
              <td>
                <div>{{ item.client.company_name }}</div>
                <div>{{ item.client.trading_name }}</div>
              </td>
              <td>
                <div>{{ JSON.parse(item.client.client_contact)[0].name }}</div>
                <div>
                  {{ JSON.parse(item.client.client_contact)[0].phone_number }} |
                  {{ JSON.parse(item.client.client_contact)[0].cel_number }}
                </div>
                <div>{{ JSON.parse(item.client.client_contact)[0].email }}</div>
              </td>
              <td class="text-center">
                <v-chip
                  class="white--text"
                  rounded
                  :color="getColor(item.priority_level)"
                >
                  {{ item.priority_level | levelLabel }}
                </v-chip>
              </td>
              <td class="text-center">
                <div>
                  {{
                    item.historic[item.historic.length - 1].created_at
                      | convertSendDate
                  }}
                </div>
                <div>
                  {{
                    item.historic[item.historic.length - 1].created_at
                      | convertSendTime
                  }}
                </div>
              </td>
              <td class="text-center">{{ item.return_date | formatDate }}</td>
              <td class="text-center">
                <v-chip
                  :color="
                    checkSituation(item) ? 'yellow darken-2' : 'blue lighten-1'
                  "
                  class="white--text"
                >
                  {{ checkSituation(item) ? 'Em aberto' : 'Em andamento' }}
                </v-chip>
              </td>
              <td class="text-right">{{ item.created_at }}</td>
              <td>
                <v-btn
                  @click="editCrm(item)"
                  icon
                >
                  <v-icon
                    small
                    color="primary"
                    >fas fa-edit</v-icon
                  >
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-dialog
        scrollable
        max-width="700"
        v-model="crmModal"
        persistent
      >
        <v-card>
          <v-card-title class="primary white--text">
            <h5 class="text-h6">Nova atividade CRM</h5>
            <v-spacer />
            <v-btn
              @click="crmModal = false"
              icon
              dark
            >
              <v-icon>fas fa-times</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <CRMForm
              @save="updateCrm"
              @cancel="closeModal"
              :crm="crm"
              :client="client"
            />
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-chip
              v-if="crm.id"
              class="white--text"
              rounded
              :color="getColor(crm.priority_level)"
            >
              {{ crm.priority_level | levelLabel }}
            </v-chip>
            <v-spacer />
            <v-btn
              @click="crmModal = false"
              outlined
              rounded
              color="primary"
              >cancelar</v-btn
            >
            <v-btn
              :disabled="checkDisabledButton"
              v-if="crm.id"
              @click="updateCRM"
              rounded
              color="primary"
              >Salvar</v-btn
            >
            <v-btn
              :disabled="checkDisabledButton"
              v-else
              @click="createCRM"
              rounded
              color="primary"
              >Salvar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {
    CRMForm: () => import('@/components/comercial/crm/CrmForm.vue'),
    SectionTitle: () => import('@/components/SectionTitle'),
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    crmModal: false,
    crm: {},
    items: [],
    tableHeaders: [
      { text: 'Título' },
      { text: 'Empresa' },
      { text: 'Contato' },
      { text: 'Prioridade', align: 'text-center' },
      { text: 'Último contato', width: 200, align: 'text-center' },
      { text: 'Próximo contato', width: 200, align: 'text-center' },
      { text: 'Alerta', align: 'text-center' },
      { text: 'Data de criação', align: 'text-right' },
      { text: '', align: '' },
    ],
  }),
  filters: {
    formatDate(value) {
      if (value.includes('-')) {
        return new Date(value).toLocaleDateString('pt-BR')
      }
      return value
    },
    levelLabel(value) {
      switch (value) {
        case 1:
          return 'Baixa'
        case 2:
          return 'Média'
        case 3:
          return 'Alta'
      }
    },
    convertSendDate(value) {
      return value.split('-')[0]
    },
    convertSendTime(value) {
      return value.split('-')[1]
    },
  },
  computed: {
    checkDisabledButton() {
      if (
        !this.crm.title ||
        !this.crm.return_date ||
        !this.crm.priority_level ||
        !this.crm.historic ||
        this.crm.historic.length == 0
      )
        return true

      return false
    },
  },
  methods: {
    getCrm() {
      this.$api
        .get(`crm/client/${this.client.id}`)
        .then((res) => {
          this.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getColor(value) {
      switch (value) {
        case 1:
          return 'blue'
        case 2:
          return 'orange'
        case 3:
          return 'error'
      }
    },
    editCrm(item) {
      console.log('teste: ', item)
      this.crm = item
      this.crm.return_date = this.crm.return_date
        .slice(0, 10)
        .split('-')
        .reverse()
        .join('/')
      this.crmModal = true
    },
    newCrm() {
      this.resetCrm()
      this.crmModal = true
    },
    resetCrm() {
      this.crm = {
        title: null,
        priority_level: null,
        return_date: null,
        send_date: null,
        is_open: true,
        status: true,
        historic: [],
      }
    },
    createCRM() {
      const convertedDate = this.crm.return_date.split('/').reverse().join('-')

      this.crm.historic.map((hist) => {
        let hour = hist.created_at.getHours() - 3
        console.log(hour)
        hist.created_at.setHours(hour)
      })

      this.$api
        .post('crm/store', {
          ...this.crm,
          return_date: convertedDate,
          send_date: this.crm.historic[0].created_at,
          client_id: this.client.id,
          seller_id: this.client.seller_id,
        })
        .then(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'CRM cadastrado com sucesso!',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
          this.crm = {}
          this.crmModal = false
          this.getCrm()
        })
        .catch((err) => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: err.response.data.message,
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
    },
    updateCRM() {
      const convertedDate = this.crm.return_date.split('/').reverse().join('-')
      this.$api
        .patch(`crm/update`, {
          ...this.crm,
          return_date: convertedDate,
          client_id: this.client.id,
        })
        .then(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'CRM atualizado com sucesso!',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
          this.getCrm()
          this.crmModal = false
        })
        .catch((err) => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: err.response.data.message,
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
    },
    checkSituation(item) {
      const today = new Date(Date.now())
      const return_date = new Date(item.return_date)

      if (today > return_date) {
        return true
      } else {
        return false
      }
    },
    closeModal(id) {
      if (this.isNewCrm) {
        this.$api
          .post(`crm/delete/`, { id })
          .then(() => {
            this.$store.commit('setModal', {
              show: true,
              component: 'success',
              text: 'CRM deletado com sucesso!',
              confirm: () => {
                this.$store.commit('setModal', {
                  show: false,
                })
              },
            })
            this.getCrm()
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            this.crmModal = false
          })
      } else {
        this.crmModal = false
      }
    },
  },
  created: function () {
    this.getCrm()
    this.resetCrm()
  },
}
</script>
